<template>
  <div style="text-align: left">
    <Card style="height:100%">
      <div class="search-con search-con-top">
        <Input clearable placeholder="输入集群名 搜索" class="search-input" v-model="queryinfo.name"
               @on-enter="namechange"
               @on-change="namechange"/>
        <Button @click="namechange" class="search-btn" type="primary">搜索</Button>
        <slot name="new_btn">
          <Button type="primary" class="search-btn"  @click="showaddmodel=true">新建</Button>
        </slot>
      </div>
      <div>
        <Table border ref="selection" :columns="columns" :data="data" ></Table>
      </div>
    </Card>
    <Modal
        v-model="showaddmodel"
        title="添加k8s资源"
        width="800"
        footer-hide>
      <div class="editor-container">
        <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="90">
          <FormItem label="名称" prop="name">
            <Input v-model.trim="formValidate.name" placeholder="输入k8s名称"></Input>
          </FormItem>
          <FormItem label="配置" prop="config">
            <div class="editor-container">
              <yaml-editor  v-model="formValidate.config" />
            </div>
          </FormItem>
          <FormItem label="名称" prop="desc">
            <Input v-model.trim="formValidate.desc" placeholder="输入描述信息"></Input>
          </FormItem>
          <FormItem>
            <Button @click="addformValidate('formValidate')" type="primary" >提交</Button>
            <Button @click="showaddmodel = false" style="position: absolute;left: 10%;">取消</Button>
          </FormItem>
        </Form>
      </div>
    </Modal>
  </div>
</template>

<script>
import {get,post} from "@/api/http.js";
import YamlEditor from '@/components/YamlEditor/index.vue';
export default {
  name: "Config",
  components: { YamlEditor },
  data () {
    return {
      content: "",
      showaddmodel: false,
      formValidate: {
        name:'',
        config:'',
        desc:'',
      },
      ruleValidate: {
        name: [ {required: true, message: "名字不能为空", trigger: "blur"}],
        config: [ {required: true, message: "配置不能为空", trigger: "blur"}],
      },
      data: [],
      columns: [
        {type: 'selection', width: 60, align: 'center'},
        {title: '集群名称', key: 'name'},
        {title: '集群描述', key: 'desc'},
        {
          title: '操 作',
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.showupdatedrawer(params.row)
                  }
                }
              }, '修 改 '),
              h('a', {
                on: {
                  click: () => {
                    this.isdelete(params.row)
                  }
                }
              }, '删 除')
            ]);
          }
        }

      ],
      queryinfo: {},
    }
  },
  methods: {
    addconfig(data) {
      post("/k8s/api/v1/config", data)
          .then((resp)=>{
            if (resp.code === 0) {
              this.$Message.success(resp.msg);
              this.showaddmodel = false
              this.formValidate = {name: "",config: "",desc:""}
            } else {
              this.$Message.error(resp.msg);
            }
          })
          .catch((e)=>{
            console.log(e)
            return e
          })
    },

    addformValidate (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.addconfig(this.formValidate)
        } else {
          this.$Message.error('请完善表单信息');
        }
      })
    },

    getdata(queryinfo) {
      const url = "/k8s/api/v1/config"
      get(url,queryinfo)
          .then((resp)=>{
            console.log(resp["data"])
            this.data = resp["data"];
          }).catch(e=>{
        return e
      })
    },
    namechange () {
      this.queryinfo.name = this.queryinfo.name.trim()
      this.getdata(this.queryinfo)
    },
  },
  created() {
    this.getdata(this.queryinfo)
  }
}
</script>

<style lang="less" scoped>
.search-con {
  padding: 10px 0;

  .search {
    &-col {
      display: inline-block;
      width: 200px;
    }

    &-input {
      display: inline-block;
      width: 250px;
      margin-left: 2px;
    }

    &-btn {
      margin-left: 2px;
    }
  }
}

</style>
